<template>
  <!-- Topbar Start -->
  <div v-if="!$auth.loading">
    <ul
      v-if="$auth.isAuthenticated"
      class="list-unstyled topbar-right-menu float-right mb-0"
    >
      <b-nav-item-dropdown
        class="notification-list nav-user"
        right
        toggle-class="arrow-none mr-0"
        menu-class="dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
      >
        <template slot="button-content">
          <span class="account-user-avatar">
            <img
              :src="$auth.user.picture"
              alt="user-image"
              class="rounded-circle"
            />
          </span>
          <span>
            <span class="account-user-name">{{ $auth.user.name }}</span>
            <span class="account-position">{{ role }}</span>
          </span>
        </template>

        <!-- item-->
        <div class="roles">
          <label> Select Roles </label>
          <template v-for="item in roles">
            <span :key="item" class="listItems" @click="changeRoles(item)">
              {{ item }}
            </span>
          </template>
        </div>
        <a @click.prevent="logout" class="dropdown-item notify-item">
          <i class="mdi mdi-logout mr-1"></i>
          <span>Logout</span>
        </a>
      </b-nav-item-dropdown>
    </ul>
    <button
      class="button-menu-mobile open-left disable-btn toggle-menu"
      @click="toggleMenu"
    >
      <i ref="navIcon" class="mdi mdi-menu toggle-menu"></i>
    </button>
  </div>
  <!-- end Topbar -->
</template>

<script>
// import simplebar from "simplebar-vue";
// import EventBus from "../eventbus";
import { GET_LOGGED_IN_USER_ROLE } from "@/graphql/queries/queries";
import { mapState } from "vuex";

export default {
  name: "topBar",
  components: {
    // simplebar
  },
  data() {
    return {
      openNav: false,
      targetDOM: {},
      showLeft: true,
      skipQuery: true,
      roles: [],
      loggedUserEmail: "",
      role: "",
    };
  },
  computed: {
    ...mapState(["currentRole"]),
  },
  watch: {
    // trigger apollo query for fetching user role on successful authentication
    async "$auth.isAuthenticated"() {
      if (this.$auth.isAuthenticated) {
        this.triggerUserRoleQuery();
      }
    },
    roles() {
      if (!this.currentRole) {
        this.$store.commit("setCurrentRole", this.roles[0]);
      }
      this.role = this.currentRole;
    },
    currentRole() {
      if (["webadmin", "zoneadmin"].includes(this.currentRole)) {
        this.$router.push("/admin");
      } else if (this.currentRole === "operator") {
        this.$router.push("/admin/operator");
      } else if (this.currentRole === "pilot") {
        if (this.$route.path !== "/admin/pilot") {
          this.$router.push("/admin/pilot");
        }
      }
    },
  },
  mounted() {
    if (this.$auth.isAuthenticated) {
      this.triggerUserRoleQuery();
    }
    window.addEventListener("click", this.isNavOpen);
  },
  methods: {
    // toggle sidebar for mobile
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");
    },
    isNavOpen(e) {
      if (e.target != this.$refs.navIcon) {
        if (e.target.id == "equipment" || e.target.id == "equip") {
          document.body.classList.add("sidebar-enable");
        } else {
          document.body.classList.remove("sidebar-enable");
        }
      }
    },

    // Log the user out
    logout() {
      this.$store.commit("setCurrentRole", "");

      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },

    changeRoles(role) {
      this.role = role;
      this.$store.commit("setCurrentRole", role);
      this.$router.go();
    },

    async triggerUserRoleQuery() {
      const claims = await this.$auth.getIdTokenClaims();
      this.loggedUserEmail = claims.email;
      this.$apollo.queries.roles.skip = false;
      this.$apollo.queries.roles.refetch();
    },
  },

  apollo: {
    roles: {
      query: GET_LOGGED_IN_USER_ROLE,
      // Reactive variables
      variables() {
        return {
          email: this.loggedUserEmail,
        };
      },
      // Disable the query
      skip() {
        return this.skipQuery;
      },
      update(data) {
        const roles = [];
        for (const role of data.users[0].user_roles) {
          roles.push(role.role);
        }
        return roles;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/sass_files";

.navbar-custom {
  position: fixed;
  top: 0;
  right: 0;
  left: $leftbar-width;
  z-index: 1001;
  min-height: $topbar-height;
  padding: 0 $grid-gutter-width;
  background-color: $bg-topbar;
  box-shadow: $shadow;

  .topbar-left {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: $leftbar-width;
    height: $topbar-height;
    text-align: center;
    background-color: $bg-topbar;
    .logo {
      line-height: $topbar-height;
      i {
        display: none;
      }
    }
  }
  .topbar-right-menu {
    li {
      float: left;
      max-height: $topbar-height;

      &.show {
        ::v-deep .nav-link {
          color: $gray-700;
        }
      }
    }

    ::v-deep .nav-link-override {
      min-width: 32px;
      padding: 0;
      margin: 0 10px;
      color: $gray-600;
      text-align: center;
    }
    ::v-deep .arrow-none::after {
      display: none;
    }
  }

  .app-search {
    padding: calc(#{$topbar-height - 38px} / 2) 0;
    overflow: hidden;

    form {
      max-width: 320px;
    }
  }
}

.topbar-dropdown {
  .nav-link {
    line-height: $topbar-height;
  }
}

/* Search */

::v-deep {
  .app-search {
    .form-control {
      height: 38px;
      padding-right: 20px;
      padding-left: 40px;
      background-color: $bg-topbar-search;
      border: none;
      box-shadow: none;
    }
    span {
      position: absolute;
      top: 0;
      left: 10px;
      z-index: 9;
      font-size: 20px;
      line-height: 38px;
    }
    .input-group-append {
      z-index: 4;
      margin-left: 0;
    }
  }
}

/* Notification */
::v-deep {
  .notification-list {
    margin-left: 0;

    .dropdown-menu.dropdown-menu-right {
      top: 100% !important;
      right: 0 !important;
      left: auto !important;
      transform: none !important;
    }

    .noti-title {
      padding: 15px 20px;
      background-color: transparent;
    }

    .noti-icon {
      font-size: 24px;
      line-height: $topbar-height;
      vertical-align: middle;
    }

    .noti-icon-badge {
      position: absolute;
      top: 22px;
      right: 18px;
      display: inline-block;
      width: 7px;
      height: 7px;
      background-color: $danger;
      border-radius: 50%;
    }

    .roles {
      padding-left: 18px;
      .listItems {
        display: block;
      }
    }

    .notify-item {
      padding: 10px 20px;

      .notify-icon {
        float: left;
        width: 36px;
        height: 36px;
        margin-right: 10px;
        line-height: 36px;
        color: $white;
        text-align: center;
        border-radius: 50%;
      }

      .notify-details {
        margin-bottom: 0;
        margin-left: 45px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        b {
          font-weight: 500;
        }
        small {
          display: block;
        }
        span {
          display: block;
          overflow: hidden;
          font-size: 13px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .user-msg {
        margin-left: 45px;
        line-height: 16px;
        white-space: normal;
      }
    }
    .topbar-dropdown-menu {
      .notify-item {
        padding: 7px 20px;
      }
    }
  }

  .profile-dropdown {
    width: 170px;
    i,
    span {
      vertical-align: middle;
    }
  }

  .nav-user {
    position: relative;
    min-height: $topbar-height;
    padding: calc(#{$topbar-height - 55px} / 2) 20px
      calc(#{$topbar-height - 39px} / 2) 57px !important;
    text-align: left !important;
    background-color: lighten($gray-100, 2.5%);
    border: 1px solid $gray-100;
    border-width: 0 1px;

    .account-user-avatar {
      position: absolute;
      top: calc(#{$topbar-height - 32px} / 2);
      left: 15px;

      img {
        width: 32px;
        height: 32px;
      }
    }
    .account-position {
      display: block;
      margin-top: -3px;
      font-size: 12px;
    }
    .account-user-name {
      display: block;
      font-weight: $font-weight-semibold;
    }
  }

  .button-menu-mobile {
    float: left;
    width: 60px;
    height: $topbar-height;
    font-size: 24px;
    line-height: $topbar-height;
    color: $dark;
    cursor: pointer;
    background-color: transparent;
    border: none;

    i {
      vertical-align: middle;
    }

    &.disable-btn {
      display: none;
    }
  }

  [data-keep-enlarged="true"] {
    .navbar-custom {
      padding-left: 0;
    }

    .button-menu-mobile {
      &.disable-btn {
        display: inline-block;
      }
    }
  }
}

@media (max-width: 1024px) {
  .navbar-custom {
    right: 0;
    left: 70px;

    .app-search {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-custom {
    left: 0;
    padding: 0 10px;
    margin: -5px -10px 0;
  }
  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }
  .nav-user {
    padding: 17px 5px 17px 57px !important;

    .account-position,
    .account-user-name {
      display: none;
    }
  }
}

// Scrollable Layout

@include media-breakpoint-up(xl) {
  body[data-leftbar-compact-mode="scrollable"] {
    .navbar-custom {
      position: absolute;
    }
  }
}

// Topbar Dark
.topnav-navbar-dark {
  background-color: $bg-topbar-dark;

  .nav-user {
    background-color: lighten($bg-topbar-dark, 5%);
    border: 1px solid lighten($bg-topbar-dark, 7.5%);
  }

  .topbar-right-menu {
    li {
      &.show {
        .nav-link {
          color: $white;
        }
      }
    }
  }

  .app-search {
    .form-control {
      color: $white;
      background-color: $bg-topbar-dark-search;
    }
    span {
      color: $gray-600;
    }
  }

  .navbar-toggle {
    span {
      background-color: rgba($white, 0.8) !important;
    }
  }
}

body[data-layout-mode="boxed"] {
  .navbar-custom {
    position: relative;
    left: 0 !important;
    margin: -$topbar-height (-$grid-gutter-width / 2) 0;
  }

  &[data-layout="topnav"] {
    .navbar-custom {
      margin: 0;
    }
  }
}
</style>
